<template lang="pug">
.wrapper
  form(@submit.prevent="onSubmit")
    .fields
      label ชื่อ-นามสกุล
      input(v-model="form.name", type="text", required)
    .fields
      label อีเมล
      input(v-model="form.email", type="email", required)

    .fields
      label เบอร์โทร
      input(
        v-model="form.phone",
        type="tel",
        :minlength="9",
        :maxlength="10",
        requiredd
      )

    .fields.text-danger(v-if="errorMessages", v-html="errorMessages")

    .fields
      button(type="submit", :disabled="isLoading") 
        span(v-if="isLoading") กำลังส่งข้อมูล...
        span(v-else) ส่งข้อมูล
</template>

<script>
import axios from "axios";
import liff from "@line/liff";
export default {
  name: "App",
  data() {
    return {
      form: {},
      isLoading: false,
      errorMessages: "",
      profile: {},
    };
  },

  mounted() {
    this.liffInit();
    // this.onSubmit();
  },

  methods: {
    async liffInit() {
      await liff.init({ liffId: "1657807330-8opg4MyJ" });
      if (liff.isLoggedIn()) {
        const isFriend = await this.getFriend();
        if (!isFriend) {
          alert("กรุณาเพิ่มเพื่อน STEPS Academy");
          window.location = "https://line.me/R/ti/p/@stepstraining";
        } else {
          await this.getProfile();
        }
      } else {
        liff.login();
      }
    },
    async getFriend() {
      const friend = await liff.getFriendship();
      return friend.friendFlag;
    },
    async getProfile() {
      const profile = await liff.getProfile();
      this.profile = profile;
    },

    async onSubmit() {
      this.isLoading = true;
      this.errorMessages = "";
      this.form.user_id = this.profile?.userId;
      try {
        await axios.post(
          "https://cny2023.digitalsetup.co/api/v1/customer",
          this.form
        );

        if (liff.isInClient()) {
          liff.closeWindow();
        } else {
          window.location = "https://line.me/R/ti/p/@stepstraining";
        }
      } catch (error) {
        this.errorMessages = error.response.data.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: "Noto Sans Thai";
  box-sizing: border-box;
  font-size: 1rem;
}
.text-danger {
  color: #a70004;
}
body {
  padding: 0;
  margin: 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

form {
  display: grid;
  gap: 1rem;
  width: 100%;
}

label {
  display: block;
}

input {
  width: 100%;
  border: solid 1px #ddd;
  border-radius: 0.5rem;
  padding: 0.75rem 0.625rem;
  outline: none;

  &:focus {
    border-color: #a70004;
  }
}

button {
  border: none;
  color: #fff;
  background: #a70004;
  border-radius: 0.5rem;
  height: 3.125rem;
  padding: 0.75rem 0.625rem;
  width: 100%;

  &:disabled {
    opacity: 0.5;
  }
}
</style>
